<template>
  <div>
    <TheNavbarHorizontal></TheNavbarHorizontal>
    <div>
      <div>
        <vs-row vs-justify="center">
          <vs-row
            type="flex"
            vs-justify="center"
            vs-align="center"
            vs-lg="12"
            vs-sm="4"
            vs-xs="12"
          >
            <div class="back-grewond2">
              <vs-row
                vs-justify="flex-center"
                vs-lg="12"
                vs-sm="4"
                vs-xs="12"
                style="padding: 10%"
              >
                <vs-row vs-lg="12" vs-sm="4" vs-xs="12">
                  <vs-col
                    vs-type="flex"
                    vs-justify="center"
                    vs-align="center"
                    vs-lg="4"
                    vs-sm="4"
                    vs-xs="12"
                  >
                  </vs-col>
                  <vs-col
                    style="padding: 2%"
                    vs-type="flex"
                    vs-justify="center"
                    vs-align="center"
                    vs-lg="4"
                    vs-sm="4"
                    vs-xs="12"
                  >
                    <img src="../assets/images/doclnia.png" />
                  </vs-col>
                </vs-row>
                <vs-row
                  vs-type="flex"
                  vs-justify="center"
                  vs-align="center"
                  vs-lg="12"
                  vs-md="12"
                  vs-sm="12"
                  vs-xs="12"
                >
                  <h1 style="color: aliceblue">patient waiver</h1>
                </vs-row>
              </vs-row>
            </div>
          </vs-row>
        </vs-row>
      </div>
    </div>
    <br />
    <vs-row vs-lg="4" vs-md="12" vs-sm="12">
      <vs-col
        vs-lg="3"
        vs-md="6"
        vs-sm="4"
        vs-justify="center"
        vs-align="center"
        style="text-align: center"
        ><h1 style="color:#132953;">1.SCOPE</h1></vs-col
      >
    </vs-row>
    <br />
    <vs-row vs-lg="12" vs-md="12" vs-sm="12" style="padding-left: 9%">
      <vs-col vs-lg="9" vs-md="12" vs-sm="12">
        <h4 style="color: #000">
          1.1 Patient is seeking the Medical Procedure generally described as
          (“Medical Service” or “Medical Procedure”). Patient wishes to receive
          this Medical Procedure in a country not of their current residence
          (“Internationally”).
        </h4>
      </vs-col>
    </vs-row>
    <br />
    <vs-row vs-lg="12" vs-md="12" vs-sm="12" style="padding-left: 9%">
      <vs-col vs-lg="9" vs-md="12" vs-sm="12">
        <h4 style="color: #000">
          1.2 Cure Journey Portal trading as Doclinia.com’s screens every
          medical facility, doctor, and travel provider prior to offering it as
          an option to its Patients to help ensure the Patient receives quality
          service. Cure Journey trading as Doclinia.com presents options that
          are available to the Patient for the desired Medical Services and
          facilitates and coordinates various aspects of the process including
          but not limited to: medical accommodations, treatment facility,
          recruitment of certain doctors, and facilitates post-operative care.
          Cure Journey trading as Doclinia.com negotiates with medical service
          and related providers to optimize pricing and care for the Medical
          Procedure within its network of medical service providers.
        </h4>
      </vs-col>
    </vs-row>
    <br />
    <vs-row vs-lg="12" vs-md="12" vs-sm="12" style="padding-left: 9%">
      <vs-col vs-lg="9" vs-md="12" vs-sm="12">
        <h4 style="color: #000">
          1.3 For the purposes of this Agreement and with regard to the Patient,
          the parties acknowledge that Cure Journey Portal trading as
          Doclinia.com acts only to coordinate the Medical Procedure, the
          Patient seeks. Cure Journey Portal trading as Doclinia.com is not
          acting as a medical advisor, physician, or providing medical advice of
          any kind. Patient acknowledges Patient has or will consult with
          trusted, qualified medical professionals prior to traveling to
          receive, or engaging in, Medical Services. Patient acknowledges
          Patient has chosen the Medical Service and related accommodations
          independently and without influence from Cure Journey Portal trading
          as Doclinia.com.
        </h4>
      </vs-col>
    </vs-row>
    <br />
    <vs-row vs-lg="12" vs-md="12" vs-sm="12" style="padding-left: 9%">
      <vs-col vs-lg="9" vs-md="12" vs-sm="12">
        <h4 style="color: #000">
          1.4 Based on Patient’s choices, Cure Journey Portal trading as
          Doclinia.com works to create a Package that is comprised of service
          items, including, but not limited to medical services. Any services
          chosen by Patient are performed or provided by third parties
          independent of Cure Journey Portal trading as Doclinia.com. Cure
          Journey Portal trading as Doclinia.com only negotiates for the Patient
          so the Patient receives the benefit of Cure Journey Portal trading as
          Doclinia.com’s volume and wholesale pricing for their Package.
        </h4>
      </vs-col>
    </vs-row>

    <br />
    <vs-row vs-lg="12" vs-md="12" vs-sm="12" style="padding-left: 9%">
      <vs-col vs-lg="9" vs-md="12" vs-sm="12">
        <h4 style="color: #000">
          1.5 Patient chooses the service items to be included in their Package
          of services on Cure Journey trading as Doclinia.com’s website
          www.doclinia.com. Each of the Patient chosen service items are booked
          and arranged by Cure Journey trading as Doclinia.com to encompass all
          aspects of the travel, accommodations, and medical service. The
          Package does not, however, include incidental charges, meals, or
          similar expenses unless specifically stated herein.
        </h4>
      </vs-col>
    </vs-row>
    <br />
    <vs-row vs-lg="12" vs-md="12" vs-sm="12" style="padding-left: 9%">
      <vs-col vs-lg="9" vs-md="12" vs-sm="12">
        <h4 style="color: #000">
          1.6 Cure Journey Portal trading as Doclinia.com agrees to provide
          reasonable assistance to Patient throughout the travel period of the
          Medical Tourism Package to ensure a pleasant experience. Any disputes
          with third-party service providers are the full responsibility of the
          Patient, regardless of any assistance provided by Cure Journey Portal
          trading as Doclinia.com. Patient acknowledges third-party providers
          may include, but are not limited to the medical service providers,
          providers of lodging, or transportation providers. Patient hereby
          waives and releases Cure Journey Portal trading as Doclinia.com from
          all liability arising from complications or conflicts arising from
          third party providers.
        </h4>
      </vs-col>
    </vs-row>
    <br />
    <vs-row vs-lg="12" vs-md="12" vs-sm="12" style="padding-left: 9%">
      <vs-col vs-lg="9" vs-md="12" vs-sm="12">
        <h4 style="color: #000">
          1.7 Patient understands and acknowledges complications can arise from
          the Medical Procedure Patient has chosen, which can result in injury
          and/or death. Patient understand Cure Journey Portal trading as
          Doclinia.com has no control or influence over complications arising
          from the Medical Procedure. Accordingly, Patient hereby waives and
          releases Cure Journey Portal trading as Doclinia.com from all
          liability arising from or damages caused by the chosen Medical
          Procedure.
        </h4>
      </vs-col>
    </vs-row>
    <!--  -->
    <br />
    <vs-row vs-lg="4" vs-md="12" vs-sm="12">
      <vs-col
        vs-lg="3"
        vs-md="6"
        vs-sm="8"
        vs-justify="center"
        vs-align="center"
        style="text-align: end"
        ><h1 style="color:#132953;">2.ESTIMATED COST</h1></vs-col
      >
    </vs-row>
    <br />
    <vs-row vs-lg="12" vs-md="12" vs-sm="12" style="padding-left: 9%">
      <vs-col vs-lg="9" vs-md="12" vs-sm="12">
        <h4 style="color: #000">
          2.1 The total estimated price for the options outlined in Patient’s
          Package is indicative (“Estimate”). The Estimate is subject to change
          based on any additions or changes requested and agreed to by Patient
          in writing. The Estimate can change based on factors not within Cure
          Journey Portal trading as Doclinia.com’s control, including, but not
          limited to additional services engaged by Patient, patient’s case
          study, or changes to services. The Estimate can also change if
          complications arise, unknown conditions are discovered, or changes to
          the Medical Services. Changes to the Medical Tourism Package schedule
          or services, including cancellation of the Package, may also result in
          additional fees or costs. Any such additional cost or charges will be
          added to the Estimate.
        </h4>
      </vs-col>
    </vs-row>
    <br />
    <vs-row vs-lg="12" vs-md="12" vs-sm="12" style="padding-left: 9%">
      <vs-col vs-lg="9" vs-md="12" vs-sm="12">
        <h4 style="color: #000">
          2.2 Rescheduling of any or all of the Package is very difficult and
          will incur additional costs. If Patient requests to reschedule the
          Package, Patient must submit to Cure Journey Portal trading as
          Doclinia.com such request in writing at least twenty-five (25)
          calendar days prior to the original travel start date with an
          explanation why the rescheduling is required.
        </h4>
      </vs-col>
    </vs-row>
    <br />
    <vs-row vs-lg="12" vs-md="12" vs-sm="12" style="padding-left: 9%">
      <vs-col vs-lg="9" vs-md="12" vs-sm="12">
        <h4 style="color: #000">
          Failure to submit the rescheduling request within such time will
          result in forfeiture of Patient’s deposit. Cure Journey Portal trading
          as Doclinia.com may agree to allow the change or refuse the change in
          its sole discretion without dispute. Patient may be required to pay
          rescheduling costs and fees and may be required to submit an
          additional deposit before Cure Journey Portal trading as Doclinia.com
          will agree to reschedule the Package.
        </h4>
      </vs-col>
    </vs-row>
    <br />
    <vs-row vs-lg="12" vs-md="12" vs-sm="12" style="padding-left: 9%">
      <vs-col vs-lg="9" vs-md="12" vs-sm="12">
        <h4 style="color: #000">
          2.3 Cure Journey Portal trading as Doclinia.com will assist with
          payment arrangements which may vary depending on requirements of the
          medical service providers, other third-party service providers, or
          international travel requirements. The estimated price for the Package
          is due and payable before the start of travel for Patient’s Medical
          Services. Failure to timely pay may result in cancellation of a
          portion or the entire Package and may result in cancellation fees. The
          Patient agrees to pay all the amounts requested by the medical service
          provider before arrival to the Medical Service destination to cover
          any incidental expenses. Any arising expenses during the surgery or
          treatment will be covered by the patient upon arrival through a credit
          or debit card guarantee.
        </h4>
      </vs-col>
    </vs-row>

    <!--  -->
    <br />
    <vs-row vs-lg="4" vs-md="12" vs-sm="12">
      <vs-col
        vs-lg="6"
        vs-md="6"
        vs-sm="9"
        vs-justify="center"
        vs-align="center"
        style="text-align: center"
        ><h1 style="color:#132953;">3.REQUIRED PATIENT INFORMATION</h1></vs-col
      >
    </vs-row>
    <br />
    <vs-row vs-lg="12" vs-md="12" vs-sm="12" style="padding-left: 9%">
      <vs-col vs-lg="9" vs-md="12" vs-sm="12">
        <h4 style="color: #000">
          3.1 Patient will be required to complete paperwork and otherwise
          provide information to Cure Journey Portal trading as Doclinia.com,
          Medical Service providers, and other third-party service providers
          prior to start of travel for the Medical Procedure. Patient agrees to
          truthfully and accurately provide any such information, including
          execution of any and all medical releases, completion of all medical
          forms, and provide all health information required to engage in the
          Medical Services. Patient understands complete, accurate, and truthful
          disclosure of medical history, health information, or other
          information requested is necessary for engaging in travel or any
          Medical Services. Cure Journey Portal trading as Doclinia.com shall
          protect Patient’s information according to EU GDPR law protections or,
          in absence thereof, protect such information with no less than a
          reasonable degree of care.
        </h4>
      </vs-col>
    </vs-row>
    <br />
    <vs-row vs-lg="12" vs-md="12" vs-sm="12" style="padding-left: 9%">
      <vs-col vs-lg="9" vs-md="12" vs-sm="12">
        <h4 style="color: #000">
          3.2 Each international destination’s laws and regulations will vary,
          including, but not limited to, laws governing visas, passports,
          immunizations, and travel restrictions. Cure Journey Portal trading as
          Doclinia.com will provide information regarding such international
          requirements, but such information may not be complete. Patient
          understands and agrees it is Patient’s sole responsibility to secure
          the necessary passports, visas, governmental authorizations,
          immunizations, or any other requirements imposed by the legal
          jurisdiction Patient will travel through or destination. Patient’s
          failure to secure the proper travel documents may result in the
          cancellation of their package and forfeiture of their deposit. Cure
          Journey Portal trading as Doclinia.com shall have no liability for
          Patient’s failure to secure all such requirements.
        </h4>
      </vs-col>
    </vs-row>

    <!--  -->
    <br />
    <vs-row vs-lg="4" vs-md="12" vs-sm="12">
      <vs-col
        vs-lg="8"
        vs-md="12"
        vs-sm="12"
        vs-justify="center"
        vs-align="center"
        style="text-align: center"
        ><h1 style="color:#132953;">4.WAIVER, INDEMNIFICATION AND ASSUMPTION OF RISK</h1></vs-col
      >
    </vs-row>
    <br />
    <vs-row vs-lg="12" vs-md="12" vs-sm="12" style="padding-left: 9%">
      <vs-col vs-lg="9" vs-md="12" vs-sm="12">
        <h4 style="color: #000">
          4.1 Patient understands and accepts that all Medical Procedures may
          involve serious risks including injury and/or death and such risks are
          inherent and cannot be eliminated or completely avoided through
          exercise of any degree of diligence or care. Participant further
          understands and accepts that traveling internationally also involves
          serious risks which are inherent and cannot be eliminated through the
          exercise of any degree of diligence or care. PATIENT ACKNOWLEDGES
          THESE RISKS AND HEREBY AGREES TO ASSUME ANY AND ALL RISK ARISING FROM
          SUCH TRAVEL OR MEDICAL PROCEDURES.
        </h4>
      </vs-col>
    </vs-row>
    <br />
    <vs-row vs-lg="12" vs-md="12" vs-sm="12" style="padding-left: 9%">
      <vs-col vs-lg="9" vs-md="12" vs-sm="12">
        <h4 style="color: #000">
          4.2 To the maximum extent allowed by law, Patient waives, releases,
          and holds harmless Cure Journey Portal trading as Doclinia.com, as
          well as its shareholders, employees, representatives, heirs, assigns,
          and/or agents (“Released Parties”) from any and all suits, claims,
          proceedings, demands and/or incidents for medical complications,
          bodily injury, death, and/or property damage of any person or entity,
          including derivative claims of any kind for pain, suffering, loss of
          capacity, loss of earning, and/or loss of consortium (“Claims”), which
          in any way arise out of or in connection with Cure Journey Portal
          trading as Doclinia.com’s Services.
        </h4>
      </vs-col>
    </vs-row>
    <!--  -->
    <br />
    <vs-row vs-lg="12" vs-md="12" vs-sm="12" style="padding-left: 9%">
      <vs-col vs-lg="9" vs-md="12" vs-sm="12">
        <h4 style="color: #000">
          4.3 Further, to the maximum extent allowed by law, Patient shall
          defend and indemnify Released Parties from any and all such Claims.
        </h4>
      </vs-col>
    </vs-row>
    <br />
    <vs-row vs-lg="12" vs-md="12" vs-sm="12" style="padding-left: 9%">
      <vs-col vs-lg="9" vs-md="12" vs-sm="12">
        <h4 style="color: #000">
          4.4 The Parties mutually waive, release, and relinquish any claim or
          demand for consequential, special, punitive, incidental, and/or
          indirect damages of any kind, regardless of the factual basis or legal
          theory from which it arises.
        </h4>
      </vs-col>
    </vs-row>
    <!--  -->
    <br />
    <vs-row vs-lg="4" vs-md="12" vs-sm="12">
      <vs-col
        vs-lg="4"
        vs-md="8"
        vs-sm="8"
        vs-justify="center"
        vs-align="center"
        style="text-align: center"
        ><h1 style="color:#132953;">5. MISCELLANEOUS</h1></vs-col
      >
    </vs-row>
    <br />
    <vs-row vs-lg="12" vs-md="12" vs-sm="12" style="padding-left: 9%">
      <vs-col vs-lg="9" vs-md="12" vs-sm="12">
        <h4 style="color: #000">
          5.1 Patient expressly agrees that this Agreement shall bind Patient as
          well as each of its successors and assigns, including, but not limited
          to Patient’s family, assigns, estate, heirs, and personal
          representatives.
        </h4>
      </vs-col>
    </vs-row>
    <br />

    <vs-row vs-lg="12" vs-md="12" vs-sm="12" style="padding-left: 9%">
      <vs-col vs-lg="9" vs-md="12" vs-sm="12">
        <h4 style="color: #000">
          5.2 The Parties agree this Contract is severable and any provisions
          contained herein which are found to be null, void, and/or
          unenforceable shall be reformed to the maximum extent allowed under
          applicable law to further the intent of the offending provision or
          removed with all else in this Contract remaining effective.
        </h4>
      </vs-col>
    </vs-row>
    <br />
    <vs-row vs-lg="12" vs-md="12" vs-sm="12" style="padding-left: 9%">
      <vs-col vs-lg="9" vs-md="12" vs-sm="12">
        <h4 style="color: #000">
          5.3 Except for the warranties expressly set forth in this Contract,
          Cure Journey Portal trading as Doclinia.com does not make any other
          express or implied warranties or representations of any kind. All such
          other warranties and representations are hereby disclaimed.
        </h4>
      </vs-col>
    </vs-row>
    <br />
    <vs-row vs-lg="12" vs-md="12" vs-sm="12" style="padding-left: 9%">
      <vs-col vs-lg="9" vs-md="12" vs-sm="12">
        <h4 style="color: #000">
          5.4 This Contract shall be governed and construed in accordance with the laws of the UAE without regard to conflict of law principles. If any Claim arises out of this Contract or Cure Journey Portal trading as Doclinia.com’s Services, the Parties shall, as a condition precedent to arbitration, engage in mediation of any dispute, unless the Parties agree otherwise in writing. Any dispute which is not settled or disposed of by mediation shall be referred to binding arbitration. The arbitration proceedings shall take place in Dubai
        </h4>
      </vs-col>
    </vs-row>
    <br />
    <vs-row vs-lg="12" vs-md="12" vs-sm="12" style="padding-left: 9%">
      <vs-col vs-lg="9" vs-md="12" vs-sm="12">
        <h4 style="color: #000">
          5.5 No waiver of any provision of document shall be deemed, or shall constitute, a waiver of any other provision, whether or not similar, nor shall any waiver constitute a continuing waiver. No waiver shall be binding unless executed in writing by the Party making the waiver.        </h4>
      </vs-col>
    </vs-row>
    <br />
    <vs-row vs-lg="12" vs-md="12" vs-sm="12" style="padding-left: 9%">
      <vs-col vs-lg="9" vs-md="12" vs-sm="12">
        <h4 style="color: #000">
          5.6 This document constitutes the entire agreement between the Parties pertaining to its subject matter and it supersedes all prior contemporaneous agreements, representations and understandings of the Parties. No supplement, modification or amendment of this Contract shall be binding unless executed in writing by all </h4>      </vs-col>
    </vs-row>
    <br />
    <Footer></Footer>
<!--  -->
    <!--  -->
  </div>
</template>
<script>
// import TheNavbarHorizontal from "../layouts/components/navbar/TheNavbarHorizontal.vue";
import Footer from "../components/footer/Footer.vue";
export default {
  data: {},
  components: {
    // TheNavbarHorizontal,
    Footer,
    // HorizontalNavMenuHeader,
    // TheNavbarVerticalFullPage,
    // VerticalNavMenuGroup
  },
};
</script>

<style>
.back-grewond2 {
  background-image: url("../assets/images/4.png");
  width: 100%;
  background-repeat: round;
  background-attachment: local;
  background-size: cover;
}
/* p:hover {
  color: rgb(85, 255, 0);
} */
/* .h1{
  color: #132953;
} */
/* .desine{
      tex
  }, */
/* a:hover {
    color: rgb(34, 255, 0);
  } */
</style>
